<template>
  <!-- 家族遗传史 -->
  <div class="illness-box">
    <Form :model="Sickness" :label-width="160">
      <Row>
        <Col span="18" offset="2">
          <div v-show="familyShow">

          <Form-item label="父亲疾病情况">
            <div class="hereditary-box">
              <Row>
                <template v-for="item in disease">
                  <Col :lg="{span: 3}" :md="{span: 6}" :key="item">
                    <Checkbox-group v-model="fatherList" @on-change="handleFather">
                      <Checkbox :label="item" :title="item" :disabled="disabledCheckbox(item, fatherList)">{{item}}</Checkbox>
                    </Checkbox-group>
                  </Col>
                </template>
                <Col :lg="{span: 6}" :md="{span: 24}">
                  <Checkbox :value="fathershow" @on-change="handleFatherOther" :disabled="disabledCheckbox('其它', fatherList)">其它</Checkbox>
                  <Input style="width: 174px;" size="small" v-model="fatherOther"></Input>
                </Col>
              </Row>
            </div>
          </Form-item>

          <Form-item label="母亲疾病情况">
            <div class="hereditary-box">
              <Row>
                <template v-for="item in disease">
                  <Col :lg="{span: 3}" :md="{span: 6}" :key="item">
                    <Checkbox-group v-model="motherList" @on-change="handleMother">
                      <Checkbox :label="item" :title="item" :disabled="disabledCheckbox(item, motherList)">{{item}}</Checkbox>
                    </Checkbox-group>
                  </Col>
                </template>
                <Col :lg="{span: 6}" :md="{span: 24}">
                  <Checkbox :value="mothershow" @on-change="handleMotherOther" :disabled="disabledCheckbox('其它', motherList)">其它</Checkbox>
                  <Input style="width: 174px;" size="small" v-model="motherOther"></Input>
                </Col>
              </Row>
            </div>
          </Form-item>

          <Form-item label="兄弟姐妹疾病情况">
            <div class="hereditary-box">
              <Row>
                <template v-for="item in disease">
                  <Col :lg="{span: 3}" :md="{span: 6}" :key="item">
                    <Checkbox-group v-model="brotherList" @on-change="handleBrother">
                      <Checkbox :label="item" :title="item" :disabled="disabledCheckbox(item, brotherList)">{{item}}</Checkbox>
                    </Checkbox-group>
                  </Col>
                </template>
                <Col :lg="{span: 6}" :md="{span: 24}">
                  <Checkbox :value="brothershow" @on-change="handleBrotherOther" :disabled="disabledCheckbox('其它', brotherList)">其它</Checkbox>
                  <Input style="width: 174px;" size="small" v-model="brotherOther"></Input>
                </Col>
              </Row>
            </div>
          </Form-item>
          </div>
        </Col>
      </Row>

      <div class="symbol-box tac" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
        <Button type="success" size="large" @click="handleSaveData">保存</Button>
      </div>
    </Form>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			Sickness: {
				father: [],
				brother: [],
				monther: [],
			},
			fatherList: [],
			motherList: [],
			brotherList: [],
			fatherOther: '',
			motherOther: '',
			brotherOther: '',
			disease: [],
			power: [],
			familyShow: false,
		};
	},
	computed: {
		fathershow() {
			return this.returnBoolean(this.fatherOther);
		},
		mothershow() {
			return this.returnBoolean(this.motherOther);
		},
		brothershow() {
			return this.returnBoolean(this.brotherOther);
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
		memberId() {
			return this.$route.params.member_id;
		},
	},
	created() {
		this.power = this.userInfo.power;
		MemberService.getBase().then((data) => {
			this.disease = data.disease;
			this.getDieaseList();
		});
	},
	methods: {
		returnBoolean(obj) {
			if (!obj) {
				return false;
			} else {
				return true;
			}
		},
		// 获取遗传病详情
		getDieaseList() {
			MemberService.historyQuery({ member_id: this.memberId }).then((data) => {
				if (data.is_hereditary) {
					this.Sickness.hereditary = 1;
					this.familyShow = true;
				} else {
					this.Sickness.hereditary = 0;
					this.familyShow = false;
				}
				let father = [];
				data.father.filter((item) => {
					return this.disease.indexOf(item) === -1 ? father.push(item) : this.fatherList.push(item);
				});
				this.fatherOther = father.join(',');

				let mother = [];
				data.mother.filter((item) => {
					return this.disease.indexOf(item) === -1 ? mother.push(item) : this.motherList.push(item);
				});
				this.motherOther = mother.join(',');

				let brother = [];
				data.brother.filter((item) => {
					return this.disease.indexOf(item) === -1
						? brother.push(item)
						: this.brotherList.push(item);
				});
				this.brotherOther = brother.join(',');
			});
		},
		handleFamily(obj, value) {
			if (obj.indexOf(value) > 0) {
				obj.push(value);
			} else {
				obj.forEach((item, index) => {
					if (item === value) {
						obj.splice(index, 1);
					}
				});
			}
		},
		disabledCheckbox(item, list) {
			return (
				(item !== '不详' && list.indexOf('不详') > -1) ||
				(item === '不详' && list.length > 0 && list.indexOf('不详') === -1)
			);
		},
		// 父亲疾病情况
		handleFather(value) {
			this.handleFamily(this.fatherList, value);
		},
		// 母亲疾病情况
		handleMother(value) {
			this.handleFamily(this.motherList, value);
		},
		// 兄弟姐妹疾病情况
		handleBrother(value) {
			this.handleFamily(this.brotherList, value);
		},
		// 其他
		handleFatherOther(value) {
			if (!value) {
				this.fatherOther = '';
			}
		},
		handleMotherOther(value) {
			if (!value) {
				this.motherOther = '';
			}
		},
		handleBrotherOther(value) {
			if (!value) {
				this.brotherOther = '';
			}
		},
		handleSaveData() {
			if (this.Sickness.hereditary === '0') {
				this.fatherList = this.motherList = this.brotherList = [];
				this.fatherOther = this.motherOther = this.brotherOther = '';
				this.fathershow = this.mothershow = this.brothershow = false;
			}
			if (this.fatherOther) {
				let arr = this.fatherOther.split(',');
				this.fatherList = this.fatherList.concat(arr);
			}
			if (this.motherOther) {
				let arr = this.motherOther.split(',');
				this.motherList = this.motherList.concat(arr);
			}
			if (this.brotherOther) {
				let arr = this.brotherOther.split(',');
				this.brotherList = this.brotherList.concat(arr);
			}
			this.Sickness.member_id = this.memberId;
			this.Sickness.father = JSON.stringify(this.fatherList);
			this.Sickness.monther = JSON.stringify(this.motherList);
			this.Sickness.brother = JSON.stringify(this.brotherList);
			MemberService.historyAdd(this.Sickness).then(() => {
				this.$Message.success('保存成功');
			});
		},
	},
};
</script>
<style lang="css" scoped>
.illness-box {
  margin-top: 40px;
}
.hereditary-box {
  margin-bottom: 24px;
}
</style>
